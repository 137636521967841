.policy-main-div {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
}


@media only screen and (min-width: 410px) {
    .policy-holder {
        margin-left: 30px;
        margin-right: 50px;
        text-align: left;
        max-width: 90vh;
        padding-top: 20px;
    }

    .policy-headers {
        font-size: 25px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .policy-info-end {
        padding-bottom: 75px;
    }
}

@media only screen and (min-width: 700px) {
    .policy-holder {
        text-align: left;
        max-width: 90vh;
        padding-top: 20px;
        margin-left: 50px;
        margin-right: 200px;
    }

    .policy-headers {
        font-size: 30px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .policy-info-end {
        padding-bottom: 50px;
    }

    .policy-contact {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
}

@media only screen and (min-width: 1050px) {
    .policy-holder {
        text-align: left;
        max-width: 90vh;
        padding-top: 20px;
        margin-left: 200px;
    }

    .policy-headers {
        font-size: 30px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .policy-info-end {
        padding-bottom: 50px;
    }

    .policy-contact {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
}