

@media only screen and (max-width: 1790px) {
    .nav-holder {
        max-width: 50%;
    }
}

@media only screen and (min-width: 700px) {
    .nav-holder {
        max-width: 60%;
    }
}

@media only screen and (min-width: 400px) {

    .bg-img-nav {
        background-image:url(../../../public/images/newbanner.png);
        height: 50px;
        border-bottom: 5px solid #E3C3AC;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .logo-color {
        display: flex;
        justify-content: left;
        max-width: 90%;
        max-height: 90%;
    }

    .nav-holder {
        display: flex;
        justify-content: end;
        float:inline-end;
        margin-right: 50px;
        position: relative;
        background-color: black;
        border-radius: 10px;
        border: 3px solid #9F957B;
        max-width: 80%;
        margin-top: -20px;
    }

    .nav {
        display: inline-block;
        font-weight: 700;
        font-size: 8px;
        color: white;
        margin: 0 2px;
        padding-bottom: 5px;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        &:hover {
            text-decoration: underline white;
            text-decoration-thickness: 2px;
        }
    }

    .selected-nav-item::after, .nav:hover::after {
        content: "▼";
        margin-top: -37px;
        margin-left: -8px;
        color: white;
        text-shadow: black 2px 3px;
        font-size: 17px;
        position: absolute;
    }

    .skull-gif {
        display: flex;
        margin-right: 10px;
        margin-bottom: -5px;
    }
}

@media only screen and (min-width: 700px) {

    .navbar {
        padding-bottom: 30px;
        width: 100%;
    }

    .bg-img-nav {
        background-image:url(../../../public/images/newbanner.png);
        height: 155px;
        border-bottom: 5px solid #E3C3AC;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .logo-color {
        display: flex;
        justify-content: left;
        max-width: 95%;
        max-height: 95%;
    }

    /* .grass-gif {
        display: flex;
        max-width: 40%;
    } */

    .skull-gif {
        display: flex;
    }

    .nav-holder {
        display: flex;
        justify-content: end;
        float:inline-end;
        margin-right: 50px;
        position: relative;
        background-color: black;
        border-radius: 10px;
        border: 5px solid #9F957B;
        max-width: 60%;
        margin-top: -20px;
    }



    .nav {
        display: inline-block;
        font-weight: 700;
        font-size: 1rem;
        color: white;
        margin: 0 10px;
        padding-bottom: 10px;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        &:hover {
            text-decoration: underline white;
            text-decoration-thickness: 2px;
        }
    }

    .nav {
        position: relative;
    }

    .selected-nav-item::after, .nav:hover::after {
        content: "▼";
        margin-top: -75px;
        margin-left: -15px;
        color: white;
        text-shadow: black 2px 5px;
        font-size: 35px;
        position: absolute;
    }

}

@media only screen and (min-width: 1050px) {

    .navbar {
        padding-bottom: 30px;
        width: 100%;
    }

    .bg-img-nav {
        background-image:url(../../../public/images/newbanner.png);
        height: 155px;
        border-bottom: 5px solid #E3C3AC;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .logo-color {
        display: flex;
        justify-content: left;
        max-width: 95%;
        max-height: 95%;
    }

    /* .grass-gif {
        display: flex;
        max-width: 40%;
    } */

    .skull-gif {
        display: flex;
    }

    .nav-holder {
        display: flex;
        justify-content: end;
        float:inline-end;
        margin-right: 50px;
        position: relative;
        background-color: black;
        border-radius: 10px;
        border: 5px solid #9F957B;
        max-width: 43%;
        margin-top: -20px;
    }



    .nav {
        display: inline-block;
        font-weight: 700;
        font-size: 1rem;
        color: white;
        margin: 0 10px;
        padding-bottom: 10px;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        &:hover {
            text-decoration: underline white;
            text-decoration-thickness: 2px;
        }
    }

    .nav {
        position: relative;
    }

    .selected-nav-item::after, .nav:hover::after {
        content: "▼";
        margin-top: -75px;
        margin-left: -15px;
        color: white;
        text-shadow: black 2px 5px;
        font-size: 35px;
        position: absolute;
    }

}