.contact-main-div {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
}


@media only screen and (min-width: 400px) {
    .contact-all-hr {
        display: flex;
        flex-direction: column;
    }

    .contact-info-header, .contact-faq-header {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        margin-left: 20px;
    }

    .contact-info, .contact-faq {
        margin-left: 35px;
        font-size: 15px;
        margin-right: 35px;
        text-align: left;
    }

    .email {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }

}

@media only screen and (min-width: 700px) {
    .contact-all-hr {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 5%;
    }

    .contact-info-header, .contact-faq-header {
        display: flex;
        flex-direction: row;
        font-size: 35px;
        font-weight: 700;
        text-align: left;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }

    .contact-info, .contact-faq {
        margin-left: 15px;
        font-size: 25px;
        margin-right: 23px;
    }

    .contact-info, .contact-faq {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 15%;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        font-size: 20px;
    }

    .email {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }
}

@media only screen and (min-width: 1050px) {

    .contact-all-hr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 5%;
    }


    .contact-info-header, .contact-faq-header {
        display: flex;
        flex-direction: row;
        font-size: 40px;
        font-weight: 700;
        text-align: left;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }

    .contact-faq-holder {
        margin-left: 10%;
    }

    .contact-info, .contact-faq {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 25%;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
        font-size: 20px;
    }

    .email {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }

}