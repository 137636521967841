.about-main-div {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
}

@media only screen and (min-width: 400px) {

    .about-all-div {
        display: flex;
        flex-direction: column;
    }

    .poster-img-div {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .poster-img {
        max-width: 60%;
    }

    .about-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 40px;
        margin-left: 40px;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 750px) {

    .about-all-div {
        display: flex;
        flex-direction: column;
    }

    .about-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 40px;
        margin-left: 40px;
        margin-bottom: 50px;
    }

    .poster-img {
        max-width: 40%;
    }
}

@media only screen and (min-width: 1050px) {

    .about-all-div {
        display: flex;
        flex-direction: row;
    }

    .about-info {
        display: flex;
        flex-direction: column;
        justify-content: end;
        float: inline-end;
        margin-right: 200px;
        text-align: left;
        margin-bottom: 40px;
        font-weight: 500;
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }

    .poster-img-div {
        max-width: 40%;
        margin-right: -70px;
    }

    .poster-img {
        max-width: 40%;
        box-shadow: 1px 1px 10px 3px gray;
    }

}