/* .links-disabled {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    text-align: left;
    padding-bottom: 20px;
    margin-left: 50px;
    margin-top: -30px;
} */
.games-main-div {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
}


@media only screen and (min-width: 400px) {
    .page-name-test {
        margin-top: 20px;
        font-size: 30px;
        margin-left: -275px;
    }

    @keyframes test {
        0%   { left:0px; top:0px;}
        25%  { left:50px; top:0px;}
        50%  { left:0px; top:0px;}
      }

    .game-list-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 20px;
    }

    .yolkhereos-img {
        max-width: 80%;
    }

    .deadhorizon-img {
        max-width: 77%;
        border-radius: 15px;
        border: 5px solid #BB253A;
    }

    .boonhill-img {
        max-width: 77%;
        border-radius: 15px;
        border: 5px solid #4D395C;
    }
    

}

@media only screen and (min-width: 700px) {
   .game-list-holder {
    margin-bottom: 50px;
   }

   .page-name-test {
    margin-left: 100px;
    font-size: 50px;
    font-family: "Pixelify Sans", sans-serif;
    text-align: left;
    font-optical-sizing: auto;
    height: 100px;
    position: relative;
    animation-name: test;
    animation-duration: 4s;
    }

    @keyframes test {
    0%   { left:0px; top:0px;}
    25%  { left:200px; top:0px;}
    50%  { left:0px; top:0px;}
    }

    .yolkhereos-img {
        max-width: 80%;
    }
}

@media only screen and (min-width: 1050px) {
    
    .game-list-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .yolkhereos-img, .deadhorizon-img, .boonhill-img {
        &:hover {
            opacity: 75%;
        }
    }

    .yolkhereos-img {
        max-width: 80%;
    }

    .deadhorizon-img {
        max-width: 70%;
        border-radius: 20px;
        border: 8px solid #BB253A;
    }

    .boonhill-img {
        max-width: 70%;
        border-radius: 20px;
        border: 8px solid #4D395C;
    }

    .page-name-test {
        margin-left: 200px;
        font-size: 100px;
        font-family: "Pixelify Sans", sans-serif;
        text-align: left;
        font-optical-sizing: auto;
        height: 100px;
        position: relative;
        animation-name: test;
        animation-duration: 4s;
    }

    @keyframes test {
    0%   { left:0px; top:0px;}
    25%  { left:200px; top:0px;}
    50%  { left:0px; top:0px;}
    }

}