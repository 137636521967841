@media only screen and (min-width: 400px) {
    .home-main-div {
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }
    .page-name {
        width: 100px;
        font-size: 50px;
        padding-left: 0px;
        margin-left: 30px;
    }

    .home-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 5%;
    }

    .latest-news-holder {
        margin-left: 10px;
    }

    .home-img {
        max-width: 80%;
        margin-bottom: 30px;
        margin-left: -45px;
    }

    .latest-news, .other-news-header {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        padding-bottom: 10px;
    }

    .small-intro, .other-news {
        text-align: left;
    }

    .goto-page {
        text-decoration: none;
        color: #4D395C;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }

    .other-news-header{
        margin-top: 30px;
    }

    .other-news {
        margin-left: 15px;
        padding-bottom: 10px;
    }

    .other-news-img {
        margin-bottom: 50px;
    }


}

@media only screen and (min-width: 700px) {
    .home-main-div {
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }
    .page-name {
        width: 500px;
        font-size: 50px;
        padding-left: 0px;
        margin-left: 30px;
    }

    .home-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 5%;
    }

    .latest-news-holder {
        margin-left: 20px;
    }

    .home-img {
        max-width: 80%;
        margin-bottom: 30px;
        margin-left: -75px;
    }

    .latest-news, .other-news-header {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        font-weight: 600;
        text-align: left;
        padding-bottom: 10px;
    }

    .small-intro, .other-news {
        text-align: left;
    }

    .goto-page {
        text-decoration: none;
        color: #4D395C;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }

    .other-news-header{
        margin-top: 30px;
    }

    .other-news {
        margin-left: 25px;
        padding-bottom: 10px;
    }

    .other-news-img {
        margin-bottom: 50px;
    }

}

@media only screen and (min-width: 1050px) {
    .home-main-div {
        font-family: "Pixelify Sans", sans-serif;
        font-optical-sizing: auto;
    }

    .page-name {
        margin-left: 200px;
        font-size: 80px;
        text-align: left;
        height: 100px;
        width: 80%;
        margin-bottom: 70px;
    }

    .small-comp {
        float: right;
        font-size: 30px;
        margin-right: 520px;
        margin-top: -25px;
    }

    .home-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 5%;
    }

    .latest-news-holder {
        margin-left: 50px;
    }

    .other-news-holder {
        margin-right: 400px;
    }

    .latest-news, .other-news-header {
        display: flex;
        flex-direction: row;
        font-size: 40px;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
    }

    .latest-news {
        margin-left: -25px;
    }

    .small-intro, .other-news {
        text-align: left;
    }

    .small-intro {
        margin-top: 20px;
        padding: 30px;
        margin-left: 5px;
        box-shadow: 1px 1px 10px 3px lightgray;
        border-radius: 10px;
    }

    .goto-page {
        text-decoration: none;
        color: #4D395C;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }

    .other-news {
        margin-left: 40px;
        padding-bottom: 10px;
    }

    .other-news-img {
        margin-left: 30px;
    }

    .home-gif-1 {
        width: 80%;
    }

}